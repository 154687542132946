import tracking, { TrackingCardPosition } from 'src/tracking'
import { CardContentType } from 'src/components/molecules/ContentCard'

import { Maybe, Recipe } from '../../graphql/generated/api-graphql'
import Router, { routes } from '../../routes/Router'
import { wediaImagesTransform } from '../Common/wedia'
import { TagVariant } from '../../components/atoms/Tag/styled'
import { toScoreLetter } from '../Common/recipe'
import { HeroProps } from '../../components/molecules/Hero'
import { Icons } from '../../components/atoms/Icon'

export const transformRecipeBigCard = (
  t: any,
  data?: Recipe | Maybe<Recipe>,
  blockName?: string
): HeroProps => ({
  title: data?.title,
  ...(data?.slug && {
    linkProps: {
      href: Router.getRouteUrl(routes.recipe, { slug: data?.slug }),
      onClick: () =>
        tracking.cards.block(
          blockName,
          CardContentType.Recipe,
          data?.title,
          TrackingCardPosition.HERO
        ),
    },
    isFullLink: true,
    buttonLabel: t('recipeCard_buttonLabel'),
  }),
  imageProps: {
    maxWidth: 1200,
    width: 1200,
    height: 480,
    withHD: true,
    withLazyLoading: true,
    images: wediaImagesTransform(data?.mainImage),
    alt: data?.title ?? '',
  },
  mobileImageProps: {
    maxWidth: 320,
    width: 320,
    height: 200,
    withHD: true,
    withLazyLoading: true,
    images: wediaImagesTransform(data?.mainImage),
    alt: data?.title ?? '',
  },
  brandLogoProps: data?.brand?.pictureLogo
    ? {
        alt: data?.brand?.name ?? ' ',
        maxWidth: 80,
        width: 80,
        height: 80,
        withHD: true,
        withLazyLoading: true,
        images: wediaImagesTransform(data?.brand.pictureLogo),
      }
    : undefined,
  bottomTags: [
    // drop all the tags in order then slice the first 2
    ...(data?.positiveFood
      ? [
          {
            label: t('positive_food'),
            variant: TagVariant.positive,
          },
        ]
      : []),
    ...(data?.tagsConsumptionTime?.[0]
      ? [
          {
            label: data?.tagsConsumptionTime?.[0]?.name,
            variant: TagVariant.meal,
          },
        ]
      : []),
    ...(data?.tagsDifficulty?.[0]
      ? [
          {
            label: data?.tagsDifficulty?.[0]?.name,
            variant: TagVariant.primary,
          },
        ]
      : []),
    ...(data?.tagsTheme?.[0]
      ? [
          {
            label: data?.tagsTheme?.[0]?.name,
            variant: TagVariant.meal,
          },
        ]
      : []),
  ].slice(0, 2),
  score: toScoreLetter(data?.nutriscoreLetter),
  duration: data?.tagsPreparationTime?.[0]?.name,
  badges: [
    ...(data?.positiveFood
      ? [
          {
            icon: Icons.positiveFoodBadge,
          },
        ]
      : []),
  ],
})
