import tracking, { TrackingCardPosition } from 'src/tracking'

import { Maybe, Recipe } from '../../graphql/generated/api-graphql'
import Router, { routes } from '../../routes/Router'
import { wediaImagesTransform } from '../Common/wedia'
import { TagVariant } from '../../components/atoms/Tag/styled'
import { toScoreLetter } from '../Common/recipe'
import { RecipeCardProps } from '../../components/molecules/RecipeCard'
import { CardContentType } from '../../components/molecules/ContentCard'
import { Icons } from '../../components/atoms/Icon'
import { transformTimeToMinutes } from '../../helpers/DateHelpers'

export const transformRecipeCard = (
  t: any,
  data?: Recipe | Maybe<Recipe>,
  blockName?: string,
  htmlTitleTag?: React.ElementType
): RecipeCardProps => {
  const duration = data?.preparationTime
    ? transformTimeToMinutes(data.preparationTime)
    : 0

  return {
    type: CardContentType.Recipe,
    title: data?.title,
    htmlTitleTag: htmlTitleTag || 'h3',
    ...(data?.slug && {
      linkProps: {
        href: Router.getRouteUrl(routes.recipe, { slug: data?.slug }),
        onClick: () =>
          tracking.cards.block(
            blockName,
            CardContentType.Recipe,
            data?.title,
            TrackingCardPosition.CONTENT
          ),
      },
    }),
    imageProps: {
      maxWidth: 384,
      width: 384,
      height: 240,
      withHD: true,
      withLazyLoading: false,
      images: wediaImagesTransform(data?.thumbnail01 ?? data?.mainImage),
      placeholder: !data?.thumbnail01 && !data?.mainImage,
      alt: data?.title ?? data?.thumbnail01?.alt ?? '',
    },
    mobileImageProps: {
      maxWidth: 140,
      width: 140,
      height: 140,
      withHD: true,
      withLazyLoading: false,
      images: wediaImagesTransform(data?.thumbnail01 ?? data?.mainImage),
      placeholder: !data?.thumbnail01 && !data?.mainImage,
      alt: data?.title ?? data?.thumbnail01?.alt ?? '',
    },
    brandLogoProps: data?.brand?.pictureLogo
      ? {
          alt: data?.brand?.name ?? ' ',
          maxWidth: 80,
          width: 80,
          height: 80,
          withHD: true,
          withLazyLoading: false,
          images: wediaImagesTransform(data?.brand.pictureLogo),
        }
      : undefined,
    tags: [
      // drop all the tags in order then slice the first 2
      ...(data?.positiveFood
        ? [
            {
              label: t('positive_food'),
              variant: TagVariant.positive,
            },
          ]
        : []),
      ...(data?.tagsConsumptionTime?.[0]
        ? [
            {
              label: data?.tagsConsumptionTime?.[0]?.name,
              variant: TagVariant.meal,
            },
          ]
        : []),
      ...(data?.tagsDifficulty?.[0]
        ? [
            {
              label: data?.tagsDifficulty?.[0]?.name,
              variant: TagVariant.primary,
            },
          ]
        : []),
      ...(data?.tagsTheme?.[0]
        ? [
            {
              label: data?.tagsTheme?.[0]?.name,
              variant: TagVariant.meal,
            },
          ]
        : []),
    ].slice(0, 2),
    score: toScoreLetter(data?.nutriscoreLetter),
    duration:
      duration && duration > 0
        ? t('recipe_duration', { duration })
        : data?.tagsPreparationTime?.[0]?.name,
    badges: [
      ...(data?.positiveFood
        ? [
            {
              icon: Icons.positiveFoodBadge,
            },
          ]
        : []),
    ],
  }
}
